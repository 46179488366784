<template>
    <div id="dashboard">
        <h1 class="r-header">
            Pengumuman
        </h1>
        <hr>
        <!-- <div class="columns is-multiline">
            <div class="column is-one-third" v-for="infoData in resultData" :key="`${infoData.id}-info`">
                <div class="box r-announcement-card" @click.prevent="gotoDetail(infoData.slug)">
                    <h2 class="r-infodata-title line-clamp">{{infoData.title}}</h2>
                    <p class="r-infodata-createdat">{{convertDate(infoData.updated_at)}}</p>
                    <p class="r-infodata-author" style="color: #a2a2a2">author: {{infoData.creater_name}}</p>
                    <div class="r-center-flex r-action-buttons" v-if="infoData.creater_id == userId">
                        <div class="r-button-custom r-delete-button" @click.stop="confirmDelete(infoData)">Hapus</div>
                        <div class="r-button-custom r-edit-button" @click.stop="gotoEdit(infoData.id)">Ubah</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="r-center-flex" v-if="resultData.length < data.total" style="margin: 20px 0px;">
            <button class="y-primary-btn y-hasspace-btn y-sm-size" style="margin: auto;" @click="addShowGetData()">Muat Lebih Banyak</button>
        </div>
        <div class="r-floatingbutton y-blue-bg" @click="$router.push({name: 'Announcement Form'})" v-if="role != 'mahasiswa' && role != 'dosen' && role != 'kalab'">
            <img :src="require('@/assets/icons/dashboard/add.svg')" alt="">
        </div> -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {

    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({

        }),

    },
    data: () => ({
        columns: [
            
        ],
    }),
    methods: {
        ...mapActions ({

        }),
        resetData() {

        },
        
        async initData() {
            const breadcrumb = [{
                name: 'Dashboard',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Dashboard')
            this.setBreadCrumb(breadcrumb)
        },
    }
}
</script>